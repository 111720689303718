import React, {useEffect} from 'react';
import Row from 'react-materialize/lib/Row';
import Col from 'react-materialize/lib/Col';
import OfferImage from './offerImage';
import OfferImage1 from './offerImage1';
import OfferImage2 from './offerImage2';
import { Link } from "gatsby"
import { gsap } from 'gsap'
import { useInView } from 'react-intersection-observer'

const Offer = () => {

  const [ref, inView] = useInView({
    rootMargin: "-50px 0px"
  })

  useEffect(() => {
    inView ? gsap.to(".offer-item", {opacity: 1, duration: 0.7, y: 10, stagger: 0.3, ease: "back", delay: .2}) : gsap.to(".offer-item", {opacity: 0, duration: 0.7, y: 60, stagger: 0.3, ease: "back", delay: .2})
    // : gsap.to(".offer-item", {duration: 1.2, y: 50, ease: "back", delay: .7})
  }, [inView])

  useEffect(() => {
    gsap.from("#offer-cta", {y: -50, duration: 2, yoyo: true, ease: "back"})
  }, [])

  return (
    <section ref={ref} className="offer color-0 center">
      <h3 className="offer-title color-1-text center">Villa Sant&eacute; vous offre</h3>
      <Row>
        <Col l={4} m={6} s={12}>
          <div className="offer-item color-1 z-depth-5">
            <OfferImage />
            <div className="offer-item-title center white-text">
              <h3>Consultations Gyn&eacute;cologiques</h3>
            </div>
          </div>
        </Col>
        <Col l={4} m={6} s={12}>
          <div className="offer-item color-1 z-depth-5">
            <OfferImage1 />
            <div className="offer-item-title center white-text">
              <h3>Troubles Sexuels</h3>
            </div>
          </div>
        </Col>
        <Col l={4} m={6} s={12}>
          <div className="offer-item color-1 z-depth-5">
            <OfferImage2 />
            <div className="offer-item-title center white-text">
              <h3>Chirurgies Majeurs</h3>
            </div>
          </div>
        </Col>
      </Row>
      <div className="offer-cta">
      <Link 
        to="/services" 
        id="offer-cta"
        className="right color-1 white-text cta z-depth-5"     
        onMouseEnter={() => gsap.to("#offer-cta", {scale: 1.1, ease: "power4.out"})}
        onMouseLeave={() => gsap.to("#offer-cta", {scale: 1, ease: "power4.out"})}   
      >
        Et plus encore!
      </Link>
      </div>
    </section>
  );
};

export default Offer;