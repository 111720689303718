import React from 'react'

const Video = () => {
  return (
    // <div  className="video">
    //   <iframe title="vs-video" src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2Fdrgrene%2Fvideos%2F10214786222626999%2F&amp;show_text=0&amp;width=560" style={{border: "none",overflow: "hidden"}} scrolling="no" frameBorder="0" allowtransparency="true" data-autoplay="true" allowFullScreen={true}></iframe>
    // </div>
    <div className="video-container color-5">
      <iframe src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2Fdrgrene%2Fvideos%2F10214786222626999%2F&show_text=0&width=560" width={560} height={308} style={{border: "none",overflow: "hidden"}} scrolling="no" frameborder={0} allowTransparency={true} data-autoplay={true} allowFullScreen={true}></iframe>
    </div>
  );
};

export default Video;