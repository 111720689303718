import React from "react"
import Header from "../components/header"
import SEO from "../components/seo"
import Welcome from "../components/welcome"
import Offer from "../components/offer"
import Video from "../components/video"
import Layout from "../components/layout"

const IndexPage = () => {

  return(
  <Layout>
    <SEO title="Gyn&eacute;cologie - Obst&eacute;trique - OBGYN - Sant&eacute; - Haiti "  />
    <Header />
    <div className="home-body">
      <Welcome />  
      <Offer />
      <Video />
    </div>
  </Layout>
)}

export default IndexPage
