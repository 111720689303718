import React from "react"
import BackgroundImage from "./bgimage"
// import { FaLinkedinIn, FaFacebookF, FaTwitter } from 'react-icons/fa'
import Garry from './garry'
import TitleHome from './titlehome'



const Header = (props) =>{

  
  return (
    <header className="header">
      <BackgroundImage
        className="bgimageHeader"
      >
      </BackgroundImage>
      <TitleHome />
      <Garry />
    </header>
  )
}
;
export default Header;