import React, {useRef, useEffect} from 'react'
import {gsap} from 'gsap'
import { navigate } from 'gatsby';

const TitleHome = () => {
  let svgEl = useRef(null)
  let textEl = useRef(null)
  let btnEl = useRef(null)
  useEffect(() => {
    gsap.to(svgEl, {opacity: 1, duration: .8, y: -20, ease: "power4.out", delay: .4})
    gsap.to(textEl, {opacity: 1, duration: .8, y: -20, ease: "slow(0.1, 0.4, false)", delay: .8})
    gsap.to(btnEl, {opacity: 1, duration: .8, y: -20, ease: "slow(0.1, 0.4, false)", delay: 1.2})
  },[])

  function handleClick() {
    navigate("/app")
  }
  
  return (
    <div className="titlehome">
      <svg 
        id="titlehomebg" 
        viewBox="0 0 280 198" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        ref={el => svgEl = el}
      >
        <path d="M96.2027 13.6688C21.095 -28.0149 9.67203e-06 34.314 0 84.4525C-9.50867e-06 133.744 17.386 215.796 96.2027 194.56C194.724 168.017 232.973 206.358 272.381 127.709C311.79 49.0606 190.087 65.7735 96.2027 13.6688Z" fill="url(#paint0_linear)"/>
        <defs>
          <linearGradient id="paint0_linear" x1="0" y1="99" x2="280" y2="99" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9400D3"/>
            <stop offset="1" stopColor="#4B0082"/>
          </linearGradient>
        </defs>
      </svg>
      <h1 
        className="titlehometext"
        ref={el => textEl = el}
      >
        Prenez rendez-vous <br /> Et faites-vous consulter en ligne 
      </h1>
      <div className="ctacontainer center">
        <button 
          className="titlehomecta" 
          onClick={handleClick}
          ref={el => btnEl = el}
        >
          Plus d'info
        </button>
      </div>
    </div>
  );
};

export default TitleHome;